<template>
  <Header />
  <section class="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
          <h1>{{ $t("cart") }}</h1>
          <h4>{{ $t("min_pizza") }}</h4>
        </div>
      </div>
    </div>
    <div class="about-content white-bg">
      <div class="container">
        <div class="row" v-if="myOrders?.length > 0">
          <!-- start order price -->
          <div class="col-lg-4 col-md-4 col-sm-12 col-12" v-if="user">
            <div class="copoun-div mb-2">
              <div>
                <label class="form-label" for="country"
                  ><h5>{{ $t("discount_code") }}</h5></label
                >
                <select
                  class="form-select"
                  id="country"
                  v-model="discount_code_id"
                  @change="setCode()"
                >
                  <option
                    v-for="code in discountCodes"
                    :key="code"
                    :value="code.id"
                  >
                    {{ code.code }}
                  </option>
                </select>
              </div>
            </div>
            <div class="copoun-div">
              <div>
                <label class="form-label" for="country"
                  ><h5>{{ $t("addressess") }}</h5></label
                >
                <select
                  class="form-select"
                  id="country"
                  v-model="cstate.address_id"
                  @change="setAddress()"
                >
                  <option
                    v-for="address in addresses"
                    :key="address.id"
                    :value="address.id"
                  >
                    {{ address.title }} ({{ address.translated_type }})
                  </option>
                </select>
              </div>
              <div class="text-center mt-2">
                <button
                  type="button"
                  class="btn btn-primary custom-btn"
                  @click.prevent="openAddress()"
                >
                  {{ $t("addAddress") }}
                </button>
              </div>
              <!-- <span class="error" v-if="cv$.address_id.$error">
                {{ cv$.address_id.$errors[0].$message }}
              </span> -->
            </div>
            <div class="service_type my-3">
              <div class="d-flex">
                <h5>{{ $t("serviceType") }} :</h5>
                <div
                  class="form-check ml-3"
                  v-for="service in services"
                  :key="service"
                >
                  <input
                    type="radio"
                    class="form-check-input"
                    name="restCheckbox"
                    v-model="cstate.serviceID"
                    :value="service.id"
                    :id="service.id"
                    @change="setServiceID()"
                  />
                  <label class="form-check-label" :for="service.id">{{
                    service.name
                  }}</label>
                </div>
              </div>
              <span class="error" v-if="cv$.serviceID.$error">
                {{ cv$.serviceID.$errors[0].$message }}
              </span>
            </div>
            <!-- <div class="calculations my-4">
              <button
                type="button"
                class="btn btn-primary blue-btn"
                :disabled="myOrders?.length == 0"
                @click.prevent="calc()"
              >
                {{ $t("getPrice") }}
              </button>
            </div> -->

            <!-- order calc -->
            <div
              class="order-history-block"
              v-if="price && myOrders?.length > 0"
            >
              <h5>{{ $t("order_details") }}</h5>
              <table class="table">
                <tbody>
                  <!-- <tr>
                    <th scope="row">{{ $t("min_price") }}</th>
                    <td class="text-end">
                      {{ parseFloat(min_order_price).toFixed(2) }}
                      {{ restaurantCurrency }}
                    </td>
                  </tr> -->
                  <tr v-if="min_order_ad">
                    <th scope="row">{{ $t("min_price_ad") }}</th>
                    <td class="text-end">
                      {{ parseFloat(min_order_ad).toFixed(2) }}
                      {{ restaurantCurrency }}
                    </td>
                  </tr>
                  <tr v-if="min_order_ad">
                    <td colspan="2">
                      <span class="note red-color"
                        >{{ $t("difrenceMsg") }}
                        {{ parseFloat(min_order_price).toFixed(2) }}
                        {{ restaurantCurrency }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("subtotal") }}</th>
                    <td class="text-end green-color">
                      {{ parseFloat(subTotal).toFixed(2) }}
                      {{ restaurantCurrency }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("delivery_fee") }}</th>
                    <td class="text-end green-color">
                      {{ parseFloat(delivery_fee).toFixed(2) }}
                      {{ restaurantCurrency }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("discount") }}</th>
                    <td class="text-end green-color">
                      {{ parseFloat(discount).toFixed(2) }}
                      {{ restaurantCurrency }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("total_tax") }}</th>
                    <td class="text-end green-color">
                      {{ parseFloat(total_tax).toFixed(2) }}
                      {{ restaurantCurrency }}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <h5>
                        {{ $t("total") }}
                        <span class="small">({{ $t("moms") }} )</span>
                      </h5>
                    </th>
                    <td class="text-end green-color">
                      <h5 class="green-color">
                        {{ parseFloat(total_amount).toFixed(2) }}
                        {{ restaurantCurrency }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- start order data -->
          <div class="col-lg-8 col-md-8 col-sm-12 col-12">
            <div>
              <div
                class="favourite-block"
                v-for="(order, index) in myOrders"
                :key="order.itemName"
              >
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                    <img :src="`${url}${order.itemImg}`" />
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-6 col-5">
                    <h5 class="mb-0">
                      <span>{{ order.itemName }}</span>
                      &nbsp;
                      <button
                        type="button"
                        @click="showEditModal(order)"
                        class="btn plus act-btn"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                    </h5>

                    <p class="rest-name">{{ order.restaurantName }}</p>
                    <p
                      class="meal-detal pe-1 mb-0 option_det"
                      v-for="option in order.order_items"
                      :key="option.id"
                    >
                      <span class="order-item-no">{{ option.quantity }} x</span>
                      {{ option.option_name }} ({{
                        option.price * option.quantity
                      }}
                      {{ order.restaurantCurrency }})
                    </p>
                    <p
                      class="meal-detal pe-1 mb-0 option_det"
                      v-for="option in order.catOptions"
                      :key="option.id"
                    >
                      <span class="order-item-no"
                        >{{ option.qnt ? option.qnt : 1 }} x</span
                      >
                      {{ option.name }} ({{
                        option.price * option.qnt ? option.qnt : 1
                      }}
                      {{ order.restaurantCurrency }})
                    </p>
                    <a
                      class="remove-cart-item"
                      @click.prevent="removeItem(index)"
                      ><img src="images/icon-tras.png" />
                      {{ $t("removeItem") }}</a
                    >
                  </div>
                  <div
                    class="
                      col-lg-4 col-md-4 col-sm-6 col-7
                      align-self-center
                      justify-content-end
                      text-end
                    "
                  >
                    <div class="quantity buttons_added cart-quan">
                      <button
                        type="button"
                        value="+"
                        class="btn plus act-btn"
                        @click="
                          order.item_qty++;
                          updateItemQty();
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <input
                        type="text"
                        min="1"
                        name="quantity"
                        v-model="order.item_qty"
                        title="Qty"
                        class="input-text qty text valid_qty"
                        size="4"
                        pattern=""
                        inputmode=""
                        disabled
                      />
                      <button
                        type="button"
                        class="btn minus act-btn"
                        @click="
                          order.item_qty--;
                          updateItemQty();
                        "
                        :disabled="order.item_qty == 1"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5" v-else>
          <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <img src="images/warning.png" alt="" />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <!-- empty cart -->
            <div class="error-div">
              <h1 class="green-h">{{ $t("min_pizza") }}</h1>
              <p>{{ $t("noItemsCart") }}</p>
            </div>
          </div>
        </div>

        <!-- buttons -->
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <router-link
              v-if="user"
              to="/Checkout"
              class="btn btn-primary custom-btn w-100"
              :class="{
                disabled:
                  myOrders?.length == 0 ||
                  (!this.cstate.address_id && this.cstate.serviceID == '1'),
              }"
              >{{ $t("checkout") }}</router-link
            >
            <router-link
              v-else
              to="/Login"
              @click.prevent="login"
              class="btn btn-primary custom-btn w-100"
              >{{ $t("login") }}</router-link
            >
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12 col-12">
            <!-- <router-link
              to="/"
              class="btn btn-primary blue-btn w-100 mm-b-3"
              @click="removeCashedCode()"
              >{{ $t("continue_shopping") }}</router-link
            > -->
          </div>
        </div>
      </div>
    </div>

    <!-- order with unavailable options -->
    <div
      class="modal fade filter-modal order-done"
      id="errOptionModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="footer-title sec-title">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="text-center mt-5 mb-5">
              <p class="text-center large mb-5">
                <span v-for="deletedItem in deletedItems" :key="deletedItem.id">
                  <!-- {{ deletedItem }}
                  <hr />
                  {{ deletedItems }} -->
                  {{ $t("item") }} {{ deletedItem.itemName }}
                  {{ $t("deleted") }}
                  {{
                    deletedItem.order_items[0]
                      ? deletedItem.order_items[0].option_name
                      : ""
                  }}
                  {{ $t("unavailble") }}<br />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Address Modal -->
    <div
      class="modal fade evaluation-modal address-modal"
      id="addressModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="addressModal"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="footer-title sec-title">
              <h5>{{ $t("addAdress") }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <AddressAddEdit @hideModal="hideModal()" @getAddresses="getAddresses()" v-if="openAddressModal"/>
          </div>
        </div>
      </div>
    </div>
    <EditItem
      @click="setListinterOnCloseModel()"
      v-if="selectedOrder && selectedOrder.itemName"
    />
  </section>
</template>
>
<script>
import { defineComponent } from "vue";
import Header from "@/components/Header.vue"; // @ is an alias to /src
import EditItem from "./Cart/EditItem.vue";
// @ is an alias to /src
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { reactive, computed } from "vue";
import { required, minLength, maxLength } from "@vuelidate/validators";
import {
  primariesNum,
  selectedItemData,
  selectedOrder,
  fetchItemData,
  myOrders,
} from "./Cart/edit-selected-order";
import AddressAddEdit from "@/components/AddressAddEdit.vue"; // @ is an alias to /src

export default defineComponent({
  components: {
    Header,
    EditItem,
    AddressAddEdit
  },
  data() {
    return {
      discountCodes: [],
      addresses: [],
      restID: "",
      restaurantCurrency: "",
      price: false,
      user: localStorage.getItem("customerToken"),
      url: localStorage.getItem("imgURL"),
      services: [
        {
          id: 1,
          name: this.$t("delivery"),
        },
        {
          id: 0,
          name: this.$t("takeaway"),
        },
      ],
      min_order_price: "",
      min_order_ad: "",
      subTotal: "",
      delivery_fee: "",
      total_amount: "",
      discount: "",
      total_tax: "",
      discount_code_id: "",

      farDistance: false,
      // array to put items which with unavalible options
      itemsErrOptions: [],
      deletedItems: [],
      openAddressModal : false,
      newAddress: false,
    };
  },
  setup() {
    const cstate = reactive({
      serviceID: "1",
      address_id: "",
    });

    const crules = computed(() => {
      return {
        serviceID: { required },
        // address_id: { required },
      };
    });

    const cv$ = useVuelidate(crules, cstate);


    return {
      itemData: selectedItemData,
      primariesNum,
      selectedOrder,
      cstate,
      cv$,
      myOrders,
    };
  },

  mounted() {
    localStorage.setItem("service_id", this.cstate.serviceID);
    this.discountCode();
    this.getOrder();
    this.getAddresses();
    this.setServiceID();
    // this.geolocate();
  },

  methods: {
    // remove cashed code
    setListinterOnCloseModel() {
      this.calc();
      this.getOrder();
    },
    removeCashedCode() {
      localStorage.removeItem("discCode");
    },
    // get discount codes
    discountCode() {
      axios
        .get("my/discount-codes")
        .then((response) => {
          // console.log(response.data);
          this.discountCodes = response.data.codes;
        })
        .catch((errors) => {
          // console.log(errors.data);
        });
    },

    // get my orders from local storage
    getOrder() {
      this.myOrders = JSON.parse(localStorage.getItem("myOrder"));
      // console.log(this.myOrders);
      this.myOrders = this.myOrders ? this.myOrders : [];
      if (this.myOrders.length > 0) {
        this.restID = this.myOrders[0].restaurant_id;
        this.restaurantCurrency = this.myOrders[0].restaurantCurrency;
        this.calc();
      }
    },

    // get addresses
    getAddresses() {
      axios
        .get("addresses")
        .then((response) => {
          this.addresses = response.data.addresses;
          // console.log(response.data);
          this.addresses = response.data.addresses.map((item) => ({
            ...item,
            is_default:
              item.is_default === 1
                ? this.cstate.address_id = item.id
                : '',
          }));
          if(this.newAddress){
            this.cstate.address_id = this.addresses[this.addresses.length - 1].id
          }
        })
        .catch((errors) => {
          // console.log(errors.data);
        });
    },

    // calculate your order
    calc() {
     
      // alert('asdasd')
      const result = this.cv$.$validate();
      let fd;
      fd = new FormData();

      if (this.cstate.serviceID == 1) {
        fd.append("address_id", this.cstate.address_id);
      }
      fd.append("restaurant_id", this.myOrders[0].restaurant_id);
      fd.append("service_info_type", this.cstate.serviceID);
      fd.append("payment_method", 1);
      fd.append("discount_code_id", this.discount_code_id);
      this.myOrders.map((item, index) => {
        fd.append(`order_items[${index}][item_id]`, item.item_id);
        fd.append(`order_items[${index}][quantity]`, item.item_qty);
        fd.append(`order_items[${index}][note]`, item.note);
        if (item.primary_value_option_id) {
          fd.append(
            `order_items[${index}][primary_option_value_id]`,
            item.primary_value_option_id
          );
          fd.append(`order_items[${index}][primary_option_quantity]`, 1);
          item.order_items.map((el, idx) => {
            fd.append(
              `order_items[${index}][template_selected_options][${idx}][option_secondary_id]`,
              el.item_id
            );
            fd.append(
              `order_items[${index}][template_selected_options][${idx}][quantity]`,
              el.quantity
            );
          });
        } else if (item.categories) {
          // console.log("yes", item.catOptions);
          item.catOptions.map((el, idx) => {
            fd.append(
              `order_items[${index}][selected_options][${idx}][option_vlaue_id]`,
              el.id
            );
            fd.append(
              `order_items[${index}][selected_options][${idx}][quantity]`,
              el.qnt ? el.qnt : 1
            );
          });
        }
      });

      // console.log(this.cv$.$error, "this.cv$.$error");
      if (!this.cv$.$error) {
        axios
          .post("orders/calc-order-item", fd)
          .then((response) => {
            this.price = true;
            // console.log("calc", response.data);
            this.min_order_price = response.data.data.min_order_price;
            this.min_order_ad =
              response.data.data.minimum_order_adjustment_amount;
            this.subTotal = response.data.data.sub_total;
            this.delivery_fee = response.data.data.delivery_fee;
            this.total_amount = response.data.data.total_amount;
            this.discount = response.data.data.coupon_discount;
            this.total_tax = response.data.data.total_tax;
          })
          .catch((errors) => {
            const Err = errors.response.data.errors;
            // console.log("err", Err);
            for (const el in Err) {
              Err[el].map((item) => {
                if (
                  item ==
                  "some options are unavailable ,please reselect your options again"
                ) {
                  let optionErrArr = el.split(".");
                  let optionErrIdx = optionErrArr[1];
                  this.itemsErrOptions.push(parseInt(optionErrIdx) + 1);
                  this.deletedItems.push(this.myOrders[optionErrIdx]);
                  // remove items with unavalible options from cart
                  this.myOrders.splice(optionErrIdx, 1);
                  localStorage.setItem(
                    "myOrder",
                    JSON.stringify(this.myOrders)
                  );
                } else {
                  this.$toast.error(item, {
                    position: "top-right",
                  });
                }
              });
            }
            if (this.itemsErrOptions.length > 0) {
              window.$("#errOptionModal").modal("show");
              // console.log("rrr", this.itemsErrOptions);
            }
          });
      }
    },

    // remove item from cart
    removeItem(index) {
      // console.log("index", index);
      this.myOrders.splice(index, 1);
      localStorage.setItem("myOrder", JSON.stringify(this.myOrders));
      this.calc();
    },

    // update itemQty in local storage
    updateItemQty() {
      localStorage.setItem("myOrder", JSON.stringify(this.myOrders));
      this.calc();
    },

    // set address id in local storage
    setAddress() {
      localStorage.setItem("addressID", this.cstate.address_id);

      this.calc();
    },

    // set address id in local storage
    setCode() {
      if (this.discount_code_id) {
        this.calc();
        localStorage.setItem("discCode", this.discount_code_id);
      } else {
        localStorage.removeItem("discCode");
      }
    },

    // set service type id in local storage
    setServiceID() {
      this.calc();
      localStorage.setItem("service_id", this.cstate.serviceID);
    },

    showEditModal(order) {
      this.selectedOrder = {};
      setTimeout(() => {
        this.selectedOrder = order;
        fetchItemData();
      }, 70);
    },

    openAddress() {
      window.$("#addressModal").modal("show");
      this.openAddressModal = true;
      // setTimeout(() => {
      //   this.geolocate();
      // }, 100);
    },
    hideModal() {
      window.$("#addressModal").modal("hide");
      this.openAddressModal = false;
      this.newAddress = true;
    },
    login() {
      localStorage.setItem("FromCart", "1");
      this.$router.push({ name: "Login" });
    }
  },
});
</script>
<style>
.remove-cart-item {
  cursor: pointer;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: #000;
  border-color: #000;
}
.valid_qty {
  opacity: 1;
  background-color: unset !important;
  color: #000;
}
/* .address-modal {
  z-index: 1050 !important; 
}
.modal-backdrop {
  z-index: 1040 !important; 
} */
.pac-container.pac-logo {
  z-index: 1051 !important; /* 1000 */
}
</style>
